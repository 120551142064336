// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as BlogPost from "../../../models/BlogPost.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LatestPosts from "../common/latest-posts/LatestPosts.res.js";
import * as TopServices from "../../common/top-services/TopServices.res.js";
import * as Api_BlogPost from "../../../api/blog-posts/Api_BlogPost.res.js";
import * as BlogPostTile from "../common/blog-post-tile/BlogPostTile.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as BlogNewsletter from "../common/blog-newsletter/BlogNewsletter.res.js";
import * as BlogPostTopBar from "../common/top-bar/BlogPostTopBar.res.js";
import * as BlogPostCategory from "../../../models/BlogPostCategory.res.js";
import * as DownloadResources from "../../resources/download-resources/DownloadResources.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogCategoryScss from "./BlogCategory.scss";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.res.js";

var css = BlogCategoryScss;

function trim(lengthOpt, shouldTrim, string) {
  var length = lengthOpt !== undefined ? lengthOpt : 200;
  if (shouldTrim) {
    return Js_string.slice(0, length, string) + "... ";
  } else {
    return string;
  }
}

function paramsConstructor(state) {
  return "?page=" + String(state.currentPageNumber);
}

var Helpers = {
  trim: trim,
  paramsConstructor: paramsConstructor
};

function BlogCategory$Component(props) {
  var trendingPosts = props.trendingPosts;
  var category = props.category;
  var blogContainer = React.useRef(null);
  var initialState = React.useMemo((function () {
          return {
                  isShowLess: true,
                  currentPageNumber: 1,
                  blogPosts: [],
                  categoryName: category.title,
                  totalPages: 1
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "FetchPosts") {
              return {
                      TAG: "SideEffects",
                      _0: (function (param) {
                          var dispatch = param.dispatch;
                          $$Promise.wait(Api_BlogPost.fetchBlogPosts(category.id, param.state.currentPageNumber, undefined), (function (x) {
                                  if (x.TAG !== "Ok") {
                                    return SentryLogger.error1({
                                                rootModule: "BlogCategory",
                                                subModulePath: {
                                                  hd: "Component",
                                                  tl: /* [] */0
                                                },
                                                value: "make",
                                                fullPath: "BlogCategory.Component.make"
                                              }, "BlogCategory::Error", [
                                                "Error",
                                                x._0
                                              ]);
                                  }
                                  var res = x._0;
                                  dispatch({
                                        TAG: "UpdatePosts",
                                        _0: res.blogPosts,
                                        _1: res.totalPages
                                      });
                                }));
                        })
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        isShowLess: !state.isShowLess,
                        currentPageNumber: state.currentPageNumber,
                        blogPosts: state.blogPosts,
                        categoryName: state.categoryName,
                        totalPages: state.totalPages
                      }
                    };
            }
          } else if (action.TAG === "UpdatePosts") {
            return {
                    TAG: "Update",
                    _0: {
                      isShowLess: state.isShowLess,
                      currentPageNumber: state.currentPageNumber,
                      blogPosts: action._0,
                      categoryName: state.categoryName,
                      totalPages: action._1
                    }
                  };
          } else {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      isShowLess: state.isShowLess,
                      currentPageNumber: action._0,
                      blogPosts: state.blogPosts,
                      categoryName: state.categoryName,
                      totalPages: state.totalPages
                    },
                    _1: (function (param) {
                        RescriptReactRouter.push(paramsConstructor(param.state));
                        Belt_Option.map(Caml_option.nullable_to_opt(blogContainer.current), (function (prim) {
                                prim.scrollIntoView();
                              }));
                        param.dispatch("FetchPosts");
                      })
                  };
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchPosts");
        }), []);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(BlogPostTopBar.make, {
                      categories: props.categories,
                      selectedCategory: category.slug
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      children: $$String.capitalize_ascii(state.categoryName) + " News"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        trim(undefined, state.isShowLess, "Cloud computing has changed the way we do business indefinitely. Most companies have come around to appreciate the convenience and cost-effectiveness of storing data with. Cloud computing has changed the way we do business indefinitely. Most companies have come around to appreciate the convenience and cost-effectiveness of storing data with"),
                                        JsxRuntime.jsx("span", {
                                              children: state.isShowLess ? "Show more" : "Show less",
                                              onClick: (function (param) {
                                                  dispatch("ToggleIntro");
                                                })
                                            })
                                      ],
                                      className: css.categoryIntro
                                    }),
                                state.blogPosts.length !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                        children: [
                                          JsxRuntime.jsx("div", {
                                                children: Belt_Array.map(state.blogPosts, (function (blogPost) {
                                                        return JsxRuntime.jsx("div", {
                                                                    children: JsxRuntime.jsx(BlogPostTile.make, {
                                                                          blogPost: blogPost,
                                                                          pageType: "BlogCategory"
                                                                        }),
                                                                    className: css.column
                                                                  }, ID.toString(blogPost.id));
                                                      })),
                                                className: css.row
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx(Pagination.make, {
                                                      currentPage: state.currentPageNumber,
                                                      totalPages: state.totalPages,
                                                      onPageClick: (function (number) {
                                                          dispatch({
                                                                TAG: "UpdatePageNumber",
                                                                _0: number
                                                              });
                                                        })
                                                    }),
                                                className: css.pagination
                                              })
                                        ]
                                      }) : JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Spinner.make, {
                                              size: "LG",
                                              color: "Teal"
                                            }),
                                        className: css.spinnerContainer
                                      })
                              ],
                              ref: Caml_option.some(blogContainer),
                              className: css.blogCategory
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(DownloadResources.make, {}),
                                JsxRuntime.jsx(TopServices.make, {}),
                                trendingPosts.length !== 0 ? JsxRuntime.jsx(LatestPosts.make, {
                                        latestPosts: trendingPosts,
                                        title: category.title + " Trending"
                                      }) : null
                              ],
                              className: css.sidebarContainer
                            })
                      ],
                      className: css.container
                    }),
                JsxRuntime.jsx(BlogNewsletter.make, {
                      orientation: "Row",
                      borderRadius: "Flat"
                    })
              ],
              className: css.blogCategoryContainer
            });
}

var Component = {
  make: BlogCategory$Component
};

function BlogCategory$default(props) {
  var category = BlogPostCategory.$$Navigation.fromJs(props.category);
  var categories = Belt_Array.map(props.categories, BlogPostCategory.Section.fromJs);
  var trendingPosts = Belt_Array.map(props.trendingPosts, BlogPost.Simple.fromJs);
  return JsxRuntime.jsx(BlogCategory$Component, {
              category: category,
              categories: categories,
              trendingPosts: trendingPosts
            });
}

var make = BlogCategory$Component;

var $$default = BlogCategory$default;

export {
  css ,
  Helpers ,
  Component ,
  make ,
  $$default as default,
}
/* css Not a pure module */
